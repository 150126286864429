import Footer from "../components/Footer"

export default function Portfolio () {
    return (
        <div>
            Portfolio Links
            <Footer />
        </div>

    )
}