import { Routes, Route } from 'react-router-dom';
import './App.css';
import Portfolio from './views/Portfolio';
import LandingPage from './views/LandingPage';
import Blog from './views/Blog';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element = {<LandingPage/>} />
        <Route path="/portfolio" element = {<Portfolio/>} />
        <Route path="/myblogs" element = {<Blog />} />
      </Routes>
    </div>
  );
}

export default App;
